<template>
    <section id="card-actions">
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-row>
                <b-col cols="8">
                    <b-card no-body class="border-warning">
                        <div class="m-1">
                            <b-row>
                                <b-col cols="4">
                                    <b-form-group label="Ingresar Código de Cotización" label-for="search-invoice">
                                        <b-input-group class="input-group-merge">
                                            <b-form-input ref="searchQuote" size="sm"
                                                id="search-invoice" v-model="searchQuery" autocomplete="off" autofocus 
                                                placeholder="Ingresa o escanea el código de barras"
                                                :readonly="articles != undefined && articles.length > 0"
                                                @keydown.enter="searchQuotes"/>
                                                <b-input-group-append>
                                                <b-button v-if="!searchQuery" size="sm" variant="outline-primary">
                                                    <feather-icon icon="SearchIcon" />
                                                </b-button>

                                                <b-button v-else size="sm" @click="resetForm" variant="outline-danger">
                                                    <feather-icon icon="XIcon" />
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>    
                                </b-col>
                                <b-col cols="6">
                                    <div v-if="articles.length > 0" class="demo-spacing-0 mt-1">
                                        <b-alert :show="articles.length > 0" variant="warning">
                                            <div class="alert-body">
                                                <feather-icon class="mr-25" icon="StarIcon"/>
                                                <span class="ml-25">
                                                    <strong>{{ articles[0].almacen_Destino }}</strong> ENVIA MATERIAL A <strong>{{ articles[0].almacen_Origen }}</strong>
                                                </span>
                                            </div>
                                        </b-alert>
                                    </div>
                                </b-col>
                                <b-col cols="2">
                                    <b-button size="sm" @click="$router.push({name: 'apps-inventories-transfer-vouchers'})" variant="warning" class="text-end float-right mr-1 mt-1">
                                        Regresar
                                    </b-button>
                                </b-col>

                                <b-col cols="6" v-if="articles.length > 0">
                                    <b-form-group label="Almacén Solicitante" label-for="warehouse">
                                        <v-select disabled v-model="form.almacen_Origen_Id" :options="wareHouses" label="nombre" :reduce="option => option.almacen_Id" placeholder="Selecciona un almacen" class="select-size-sm"
                                            :clearable="false"
                                            @input="selectWarehouse" />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="6" v-if="articles.length > 0">
                                    <b-form-group label="Almacén Suministrador" label-for="warehouse">
                                        <v-select disabled v-model="form.almacen_Destino_Id" :options="destinationWarehouses" label="nombre" :reduce="option => option.almacen_Id" placeholder="Selecciona un almacen" class="select-size-sm" 
                                            :clearable="false"/>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="6" v-if="articles.length > 0">
                                    <b-form-group label="Vale se asigna al chofer" label-for="operator">
                                        <v-select :disabled="disabledChofer" v-model="form.chofer_Id" :options="operators" 
                                            :reduce="option => option.id" 
                                            label="label" placeholder="Selecciona un chofer" class="select-size-sm"
                                        :clearable="false" />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="6" v-if="articles.length > 0">
                                    <b-form-group label="Folio" label-for="folio">
                                        <b-form-input disabled size="sm" id="folio" v-model="form.folio" autocomplete="off" placeholder="Ingresa el folio" />
                                    </b-form-group>
                                </b-col>


                                <b-col v-if="articles.length > 0" cols="12" md="12">
                                    <global-articles :articles="articles" :loading="loading" :isColumnDetails="true" :isEdit="!isSameWarehouse" :isSticky="true" />
                                </b-col>
                            </b-row>
                        </div>
                        
                    <b-card-footer v-if="articles.length > 0">
                            <b-button ref="saveButton" :disabled="isSameWarehouse" :block="isMobile" @click="store"
                                size="sm" variant="warning" class="text-end float-right mr-1"
                                v-b-tooltip.hover.top="showTooltip ? `Enviar material F12` : ``">
                                <feather-icon icon="ArrowUpRightIcon" />
                                ENVIAR MATERIAL
                            </b-button>

                            <b-button :block="isMobile" @click="resetForm" size="sm" variant="outline-secondary" class="text-end float-right mr-1">
                                <feather-icon icon="XCircleIcon" />
                                Cancelar
                            </b-button>

                            <span v-if="isSameWarehouse" class="help-block">
                                <b-badge variant="danger">Solo el suministrador está autorizado para enviar el material.</b-badge>
                            </span>
                        </b-card-footer>
                    </b-card>
                </b-col>
            </b-row>
        </b-overlay>
    </section>
</template>

<script>
import _ from 'lodash'
import {
    BRow, BCol, BCard, BCardBody, BCardFooter, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab, BListGroup, BListGroupItem, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import store from '@/store';

import GlobalArticles from '@/@core/components/GlobalArticles.vue'

import WebOperadoresProvider from '@/providers/WebOperadores';
const WebOperadoresResource = new WebOperadoresProvider();

import TransferVouchersProvider from '@/providers/TransferVouchers'
const TransferVouchersResource = new TransferVouchersProvider()

export default {
    name: 'TransferVouchersSend',
    components: {
        BRow, BCol, BCard, BCardBody, BCardFooter, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
        BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
        BBadge, BCardSubTitle, BCardTitle, BTabs, BTab, BListGroup, BListGroupItem, BTooltip, VBTooltip,

        //
        BCardActions,
        flatPickr,
        vSelect,
        GlobalArticles,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    data() {
        return {
            loading: false,
            searchQuery: '',
            articles: [],
            wareHouses: [],
            destinationWarehouses: [],
            operators: [],
            isMobile: false,
            isSameWarehouse: false,
            form: {
                almacen_Origen_Id: null,
                almacen_Destino_Id: null,
                chofer_Id: null,
                folio: null,
            }
            
        }
    },
    computed: {
        isAdministrator() {
            return store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol'] ||
            store.getters['auth/getUser'].customRol.id == store.getters['auth/getSuperCashierRol'] ||
            store.getters['auth/getUser'].customRol.id == store.getters['auth/getGerenteRol']
        },
        showTooltip() {
            return this.findSetting('ACTIVAR_ATAJOS_TECLADO')
        },
        disabledChofer() {
            return this.articles.length > 0 && !!this.articles[0]?.chofer && !!this.articles[0]?.chofer_Id;
        }


    },
    async mounted() {
        // Escucha el evento de teclado cuando el componente esté montado
        window.addEventListener('keydown', this.handleKeyPress);
        window.addEventListener('resize', this.handleResize);

        this.handleResize()
        this.wareHouses = store.getters['auth/getWareHouses']
        this.destinationWarehouses = store.getters['auth/getWareHouses']

        //Obtener el armacen del usuario
        const wareHouseId = store.getters['auth/getWarehouseId']
        if (wareHouseId) {
            //this.form.almacen_Origen_Id = wareHouseId
            //this.destinationWarehouses = this.destinationWarehouses.filter(item => item.almacen_Id != wareHouseId)
        }

        await this.getOperators()
    },
    beforeDestroy() {
        // Limpia el listener de eventos cuando el componente se destruya
        window.removeEventListener('keydown', this.handleKeyPress);
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleKeyPress(event) {
            if(this.findSetting('ACTIVAR_ATAJOS_TECLADO')) {

                // Verifica si la tecla presionada es F12
                if (event.key === 'F12') {

                    event.preventDefault();  // Prevenir la acción predeterminada del navegador

                    // Emula el clic en el botón de guardar
                    this.$refs.saveButton.click();
                }
            }
        },
        selectWarehouse(evt) {
            this.destinationWarehouses = []
            const warehouses = store.getters['auth/getWareHouses']
            this.destinationWarehouses = warehouses.filter(item => item.almacen_Id != evt)
        },
        async getOperators() {           
            if (this.operators.length <= 0) {
                this.loading = true
                const { data } = await WebOperadoresResource.index({})
                this.loading = false
                if (data.isSuccesful) {
                    this.operators = data.data
                        .map(item => ({...item, label: `${item.nombre} ${item.apellidos}`}))
                        .filter(item => item.activo && item.tipo == 'Chofer' && item.id !== 1)
                }
            }        
        },
        async searchQuotes() {
            this.loading = true
            this.articles = []
            try {
                const { data } = await TransferVouchersResource.getAllByDoctoVeIdAndTipoMov(this.searchQuery, 'P')
                if (data.isSuccesful) {
                    this.articles = data.data.map(i => ({...i, unidades: 0, unidad_Venta: i.um}))
                    this.form.almacen_Origen_Id = this.articles[0].almacen_Origen_Id
                    this.form.almacen_Destino_Id = this.articles[0].almacen_Destino_Id
                    this.form.chofer_Id = this.articles[0].chofer_Id
                    this.form.folio = this.articles[0].folio  
                    
                    // if (this.isAdministrator) {
                    //     this.isSameWarehouse = true
                    // } else {
                    //     this.isSameWarehouse = this.form.almacen_Destino_Id === store.getters['auth/getWarehouseId'];
                    // }
                    
                    this.isSameWarehouse = /*!this.isAdministrator &&*/ this.form.almacen_Destino_Id !== store.getters['auth/getWarehouseId'];

                    // console.log(store.getters['auth/getWarehouseId'], this.form.almacen_Destino_Id, this.isSameWarehouse)

                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
            finally {
                this.loading = false
            }
        },
        async store() {

            // console.log(this.form)
            // return false

            // Validar si se ha seleccionado un chofer
            if (!this.form.chofer_Id) {
                this.danger('Debes seleccionar un chofer');
                return;
            }

            // Inicializar y validar los artículos
            let hasValidUnits = false;
            this.articles.forEach(element => {
                element.unidades = parseFloat(element.unidades);
                element.tipo_Mov = 'E';
                element.cerrada = false;
                element.chofer_Id = this.form.chofer_Id;

                if (element.unidades > 0) {
                    hasValidUnits = true;
                }
            });

            // Validar si al menos algún artículo tiene una cantidad mayor a 0
            if (!hasValidUnits) {
                this.danger('Debes enviar al menos un artículo');
                return;
            }
            
            this.loading = true
            try {
                const { data } = await TransferVouchersResource.store(this.articles)
                if (data.isSuccesful) {
                    this.$swal({
                        title: '¡Éxito!',
                        html: data.message,
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: '#556ee6',

                    }).then(async () => {
                        await this.$router.push({name: 'apps-inventories-transfer-vouchers'})
                    })
                } else {
                    if (data.data.length > 0) {
                        const message = `
                            <ul style="text-align: left!important;">
                                ${data.data.map(element => `<li><small>${element.articulo}</small></li>`).join('')}
                            </ul>
                            <p style="text-align: left!important;">
                                <small><strong>Verifica lo siguiente:</strong></small><br>
                                <small><strong>- No enviar más del doble de lo solicitado</strong></small><br>
                                <small><strong>- No enviar cuando ya hay envío de artículo pendiente por recibir</strong></small><br>
                                <small><strong>- No enviar material cuando ya se envió más de lo solicitado previamente</strong></small>
                            </p>`;

                        this.$swal({
                            title: `Artículos que no se pueden enviar`,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            showClass: {
                                popup: 'animate__animated animate__bounceIn',
                            },
                            buttonsStyling: false,
                            confirmButtonText: 'Aceptar',
                            icon: 'error',
                            html: message
                        });

                    } else {
                        this.danger(data.message)
                    }
                }
            }catch(e) {
                this.handleResponseErrors(e)
            }
            finally {
                this.loading = false
            }
        },
        handleResize() {
            this.isMobile = window.innerWidth < 576;
        },
        resetForm(){
            this.searchQuery = ''
            this.articles = []
            this.$nextTick(() => {
                this.$refs.searchQuote.focus()
            })
        }
    },
    watch: {

    }
}

</script>